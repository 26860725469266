<template>
<svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 522.16 463.09">
  <defs>
    <style>
      .cls-1 {
        fill: #00053f;
      }
    </style>
  </defs>
  <!-- Generator: Adobe Illustrator 28.6.0, SVG Export Plug-In . SVG Version: 1.2.0 Build 709)  -->
  <g>
    <g id="Capa_1">
      <path class="cls-1" d="M20.11,361.79c6.15,3.44,13.16,5.15,21.01,5.15,6.64,0,12.61-1.28,17.9-3.85,5.28-2.57,9.65-5.93,13.08-10.08,3.44-4.15,5.87-8.65,7.31-13.48v-18.01h-42.14v14.27h24.58c-1.51,4.53-3.91,8-7.19,10.42-3.29,2.42-7.31,3.62-12.06,3.62-6.95,0-12.4-2.06-16.37-6.17-3.96-4.11-5.95-9.83-5.95-17.16,0-6.87,1.89-12.36,5.66-16.48,3.78-4.11,8.8-6.17,15.06-6.17,3.62,0,6.78.7,9.46,2.1,2.68,1.4,4.74,3.42,6.17,6.06h21.41c-2.12-8.15-6.44-14.54-12.97-19.14-6.53-4.61-14.56-6.91-24.07-6.91-7.85,0-14.84,1.72-20.95,5.16-6.12,3.44-10.89,8.23-14.33,14.38-3.44,6.16-5.15,13.16-5.15,21.01s1.72,14.84,5.15,20.95c3.44,6.12,8.23,10.89,14.39,14.33Z"/>
      <path class="cls-1" d="M20.11,448.79c6.15,3.44,13.16,5.15,21.01,5.15,6.64,0,12.61-1.28,17.9-3.85,5.28-2.57,9.65-5.93,13.08-10.08,3.44-4.15,5.87-8.65,7.31-13.48v-18.01h-42.14v14.27h24.58c-1.51,4.53-3.91,8-7.19,10.42-3.29,2.42-7.31,3.62-12.06,3.62-6.95,0-12.4-2.06-16.37-6.17-3.96-4.11-5.95-9.83-5.95-17.16,0-6.87,1.89-12.36,5.66-16.48,3.78-4.11,8.8-6.17,15.06-6.17,3.62,0,6.78.7,9.46,2.1,2.68,1.4,4.74,3.42,6.17,6.06h21.41c-2.12-8.15-6.44-14.54-12.97-19.14-6.53-4.61-14.56-6.91-24.07-6.91-7.85,0-14.84,1.72-20.95,5.16-6.12,3.44-10.89,8.23-14.33,14.38-3.44,6.16-5.15,13.16-5.15,21.01s1.72,14.84,5.15,20.95c3.44,6.12,8.23,10.89,14.39,14.33Z"/>
      <polygon class="cls-1" points="107.15 286.86 107.15 366.38 151.89 366.38 151.89 351.43 126.52 351.43 126.52 286.86 107.15 286.86"/>
      <path class="cls-1" d="M236.34,290.95c-6.23-3.48-13.08-5.21-20.56-5.21s-14.32,1.73-20.56,5.21c-6.23,3.47-11.18,8.3-14.84,14.49-3.66,6.2-5.49,13.18-5.49,20.95s1.82,14.79,5.49,21.03c3.66,6.22,8.61,11.07,14.84,14.55,6.23,3.47,13.08,5.21,20.56,5.21s14.31-1.75,20.5-5.21c6.2-3.48,11.1-8.33,14.73-14.55,3.62-6.23,5.43-13.24,5.43-21.03s-1.79-14.75-5.37-20.95c-3.59-6.19-8.5-11.02-14.73-14.49ZM231.01,343.16c-3.82,4.23-8.89,6.34-15.23,6.34s-11.54-2.08-15.36-6.28c-3.8-4.2-5.72-9.81-5.72-16.83s1.92-12.71,5.72-16.86c3.82-4.17,8.94-6.23,15.36-6.23s11.42,2.1,15.23,6.28c3.82,4.2,5.72,9.81,5.72,16.82s-1.9,12.54-5.72,16.77Z"/>
      <path class="cls-1" d="M342.13,319.15c2.46-3.1,3.68-6.99,3.68-11.68,0-6.34-2.28-11.36-6.84-15.05-4.58-3.71-10.9-5.56-18.97-5.56h-35.79v79.52h37.04c8.3,0,14.78-1.93,19.42-5.82,4.65-3.89,6.98-9.15,6.98-15.81,0-4.59-1.4-8.7-4.2-12.29-2.8-3.59-6.49-5.87-11.1-6.86,4.07-1.2,7.34-3.36,9.79-6.45ZM303.57,302.38h12.68c3.18,0,5.61.71,7.3,2.16,1.7,1.43,2.55,3.51,2.55,6.22s-.85,4.79-2.55,6.17c-1.69,1.4-4.12,2.1-7.3,2.1h-12.68v-16.65ZM325.37,348.54c-1.78,1.47-4.29,2.2-7.54,2.2h-14.26v-17.55h14.03c3.25,0,5.79.79,7.65,2.37,1.85,1.58,2.78,3.77,2.78,6.57s-.9,4.93-2.66,6.4Z"/>
      <path class="cls-1" d="M425.32,352.34l4.76,14.03h20.51l-28.78-79.52h-22.43l-28.77,79.52h20.27l4.76-14.03h29.68ZM410.48,308.28l9.85,29.1h-19.58l9.73-29.1Z"/>
      <polygon class="cls-1" points="521.04 351.43 495.66 351.43 495.66 286.86 476.3 286.86 476.3 366.38 521.04 366.38 521.04 351.43"/>
      <path class="cls-1" d="M267,89.72c-.92-8.63-2.95-16.93-5.91-24.77h0C246.68,26.99,209.98,0,166.97,0c-55.56,0-100.61,45.04-100.61,100.61,0,48.79,34.73,89.47,80.82,98.66,1.89,2.65,3.85,4.69,5.61,6.2,5.19,4.47,9.88,9.5,13.86,15.07l.23.33c8.23,11.52,29.62,14.34,28.22,8.94-3.39-1.13-10.56-16.93-15.72-29.36,37.57-4.61,68.73-29.94,81.7-64.21,2.97-7.83,4.99-16.12,5.91-24.74h0c.39-3.59.59-7.21.59-10.89s-.2-7.31-.58-10.89ZM150.88,125.74c-5.45,4.66-11.84,8.07-18.36,11.12-4.88,2.28-9.27,5.29-12.83,9.37-2.14,2.45-3.93,5.49-2.02,8.55,5.98,9.6,9.92,20.6,19.52,27.76,1.2.89,2.31,1.86,3.35,2.92-28.85-8.98-51.39-32.27-59.33-61.57,2.57-1.53,4.41-4.06,6.33-6.64,6.6-8.85,8.52-18.79,8.2-29.65-.33-10.83-.44-21.87,6.62-31.19,7.05-9.3,12.21-19.65,17.11-30.16.17-.38.33-.72.49-1.06,9.32-5.83,19.8-9.97,31.02-12.01.78,2.61,3.7,4.09,5.74,5.83,3.05,2.59,5.96.38,8.26-1.79,1.8-1.71,3.44-3.57,5.09-5.43,5.64.19,11.15.92,16.47,2.11-.05.08-.1.16-.16.24-1.68,2.74,2,3.7,3.49,5.3,1.49,1.6,3.23,2.99,4.8,4.55.78.78,1.91,2.08,1.24,2.83-2.96,3.31-2.37,7.7-3.53,12.06-1.69-1.55-2.93-2.62-4.1-3.76-7.36-7.16-10.67-7.37-18.9-.97-1.73,1.35-3.42,2.81-4.87,4.45-4.3,4.86-4.38,7.21.27,11.76,6.18,6.04,12.72,11.71,19.8,16.7,6.17,4.35,6.55,5.46,3.94,12.3-.72,1.89-1.41,3.81-1.8,5.77-.37,1.82-.39,3.8,1.66,4.81,1.77.87,3.26-.1,4.47-1.26,1.58-1.53,2.99-3.24,4.47-4.86,3.22-3.52,14.81-5.77,18.58-3.62,2.25,1.29,2.3,9.34-.16,14.59-.92,1.95-2.2,3.93-3.8,5.34-7.62,6.65-15.44,13.07-24.3,18.07-4.32,2.43-8.77,3.56-13.76,2.17-8.6-2.39-16.12-.56-23.02,5.34ZM174.88,189.11c-.2-.44-.41-.92-.63-1.4-.55-1.43-.86-2.25-.86-2.25-1.12-3.81-.07-6.93,3.39-9.77,3.41-2.79,6.85-5.64,9.74-8.94,4.76-5.43,8-11.36,4.33-19.01-1.39-2.92-1.83-6.33-2.48-9.57-.72-3.6.48-6.56,3.68-8.5,11.52-6.99,21.08-16.6,32.19-24.13,4-2.71,6.5-6.28,7.43-11.12.8-4.14,1.85-8.29,4.28-11.86,5.9-8.69,7.24-18.25,5.73-28.47-.1-.67-.18-1.33-.25-2,7.22,11.07,12.05,23.86,13.73,37.61-.38,3.58-.58,7.21-.58,10.89s.2,7.31.59,10.89c-5.06,41.35-38.52,73.93-80.28,77.62Z"/>
      <path class="cls-1" d="M255.83,100.61c0,3.69-.22,7.32-.66,10.89-.39-3.58-.59-7.21-.59-10.89s.2-7.31.58-10.89c.44,3.57.67,7.2.67,10.89Z"/>
      <path class="cls-1" d="M355.18,0c-43,0-79.71,26.98-94.1,64.94h0c-2.97,7.84-5,16.15-5.92,24.77-.38,3.58-.58,7.21-.58,10.89s.2,7.31.59,10.89c.92,8.62,2.93,16.91,5.91,24.74,12.97,34.27,44.13,59.6,81.7,64.21-5.17,12.43-12.33,28.23-15.72,29.36-1.4,5.4,19.99,2.58,28.22-8.94l.23-.33c3.98-5.57,8.67-10.6,13.86-15.07,1.76-1.52,3.72-3.55,5.61-6.2,46.09-9.2,80.82-49.87,80.82-98.66,0-55.56-45.04-100.61-100.61-100.61ZM348.77,185.46s-.31.83-.86,2.25c-.22.48-.43.95-.63,1.4-41.76-3.69-75.23-36.26-80.28-77.62h0c.39-3.59.59-7.21.59-10.89s-.2-7.31-.58-10.89c1.68-13.76,6.51-26.55,13.73-37.61-.07.67-.16,1.33-.25,2-1.51,10.22-.17,19.78,5.73,28.47,2.43,3.57,3.48,7.72,4.28,11.86.93,4.84,3.43,8.41,7.43,11.12,11.1,7.53,20.67,17.14,32.19,24.13,3.2,1.94,4.41,4.9,3.68,8.5-.65,3.24-1.09,6.65-2.48,9.57-3.67,7.65-.43,13.58,4.33,19.01,2.89,3.3,6.34,6.15,9.74,8.94,3.45,2.83,4.5,5.95,3.39,9.77ZM381.62,185.46c1.03-1.06,2.15-2.03,3.35-2.92,9.6-7.16,13.54-18.16,19.52-27.76,1.91-3.06.12-6.09-2.02-8.55-3.56-4.08-7.95-7.1-12.83-9.37-6.53-3.05-12.92-6.46-18.36-11.12-6.9-5.91-14.42-7.73-23.02-5.34-4.98,1.38-9.43.26-13.76-2.17-8.86-5-16.68-11.42-24.3-18.07-1.6-1.4-2.88-3.39-3.8-5.34-2.46-5.26-2.41-13.31-.16-14.59,3.76-2.16,15.36.1,18.58,3.62,1.49,1.63,2.89,3.33,4.47,4.86,1.2,1.16,2.69,2.13,4.47,1.26,2.06-1.01,2.03-2.99,1.66-4.81-.39-1.96-1.09-3.88-1.8-5.77-2.61-6.85-2.23-7.95,3.94-12.3,7.08-4.99,13.62-10.66,19.8-16.7,4.65-4.55,4.57-6.9.27-11.76-1.45-1.64-3.14-3.1-4.87-4.45-8.22-6.4-11.54-6.2-18.9.97-1.17,1.14-2.4,2.21-4.1,3.76-1.15-4.36-.56-8.75-3.53-12.06-.67-.75.47-2.06,1.24-2.83,1.57-1.55,3.3-2.94,4.8-4.55,1.49-1.6,5.17-2.57,3.49-5.3-.05-.08-.1-.16-.16-.24,5.32-1.2,10.83-1.92,16.47-2.11,1.66,1.86,3.3,3.72,5.09,5.43,2.3,2.17,5.21,4.38,8.26,1.79,2.05-1.74,4.97-3.22,5.74-5.83,11.21,2.04,21.7,6.18,31.02,12.01.16.33.32.68.49,1.06,4.9,10.51,10.06,20.86,17.11,30.16,7.06,9.32,6.95,20.36,6.62,31.19-.33,10.86,1.6,20.8,8.2,29.65,1.92,2.58,3.76,5.11,6.33,6.64-7.93,29.3-30.47,52.58-59.33,61.57Z"/>
      <path class="cls-1" d="M267.58,100.61c0,3.68-.2,7.3-.59,10.88-.44-3.57-.66-7.2-.66-10.88s.22-7.32.67-10.89c.38,3.58.58,7.21.58,10.89Z"/>
      <path class="cls-1" d="M154.7,373.29c19.99,0,30.05,11.43,30.05,25.82,0,10.51-5.71,19.65-17.71,23.31l18.17,31.42h-25.02l-15.99-29.7h-2.4v29.7h-22.39v-80.55h35.3ZM152.88,392.03h-11.08v16.8h11.08c6.06,0,9.14-2.97,9.14-8.45,0-5.03-3.08-8.34-9.14-8.34Z"/>
      <path class="cls-1" d="M342.27,373.29h22.28v46.73c0,8.34,3.77,13.6,12.23,13.6s12.45-5.26,12.45-13.6v-46.73h22.39v46.73c0,22.96-15.99,34.62-35.3,34.62s-34.05-11.65-34.05-34.62v-46.73Z"/>
      <path class="cls-1" d="M490.92,426.65h-11.08v27.19h-22.39v-80.55h33.48c19.99,0,30.16,11.31,30.16,26.85,0,14.05-9.71,26.51-30.16,26.51ZM488.75,408.83c6.74,0,9.48-3.43,9.48-8.68s-2.74-8.8-9.48-8.8h-8.91v17.48h8.91Z"/>
      <path class="cls-1" d="M285.29,377.54c-6.23-3.48-13.08-5.21-20.56-5.21s-14.32,1.73-20.56,5.21c-6.23,3.47-11.18,8.3-14.84,14.49-3.66,6.2-5.49,13.18-5.49,20.95s1.82,14.79,5.49,21.03c3.66,6.22,8.61,11.07,14.84,14.55,6.23,3.47,13.08,5.21,20.56,5.21s14.31-1.75,20.5-5.21c6.2-3.48,11.1-8.33,14.73-14.55,3.62-6.23,5.43-13.24,5.43-21.03s-1.79-14.75-5.37-20.95c-3.59-6.19-8.5-11.02-14.73-14.49ZM279.97,429.75c-3.82,4.23-8.89,6.34-15.23,6.34s-11.54-2.08-15.36-6.28c-3.8-4.2-5.72-9.81-5.72-16.83s1.92-12.71,5.72-16.86c3.82-4.17,8.94-6.23,15.36-6.23s11.42,2.1,15.23,6.28c3.82,4.2,5.72,9.81,5.72,16.82s-1.9,12.54-5.72,16.77Z"/>
    </g>
  </g>
</svg>
</template>

<script>
export default {
  name: 'ProjectLogo'
}
</script>
